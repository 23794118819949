<template>
  <!-- p-10 -->
  <div class="sm:py-4 w-full max-w-full-hd mx-auto" style="transition: 0.3s">
    <div class="p-10 sm:pt-0 sm:px-5">
      <component
        :user="user"
        :is="current_component"
        @enableUpdatingProfile="
          (enable_updating) => {
            current_component = enable_updating
              ? 'UpdateProfile'
              : 'ProfileInfo'
          }
        "
      />
    </div>
    <RevelationMobileButton />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      current_component: 'ProfileInfo',
    }
  },
  computed: {
    ...mapGetters({
      open_sideBar: 'sidebar/activated_sibeBar',
      user: 'auth/user',
    }),
  },
}
</script>
